import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router";

import LocationForm from "../AppOrders/components/LocationForm";
import StatusForm from "../AppOrders/components/StatusForm";
import CapturePaymentForm from "./components/CapturePaymentForm";
import RefundForm from "./components/RefundForm";
import RescheduleForm from "./components/RescheduleForm";
import calculatePricing from "../../utils/calculatePricing";
import Sidebar from "../../global/components/Sidebar/Sidebar";
import { getSettings } from "../../actions/settings";
import { getSettingsFlorida } from "../../actions/settings_florida";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

// Redux
import { getOrder } from "../../actions/orders";
import { updateOrderPayment } from "../../actions/orders";
import { updateLocalOrder } from "../../actions/localOrder";
import { setOrderToEdit } from "../../actions/editOrder";
import { updateOrderStatus } from "../../actions/orders";
import { getPaymentMethods, fetchSetupSecret } from "../../actions/payment";

import getPaymentIntent from "../../utils/getPaymentIntent";

import { authorizeGuest } from "../../actions/auth";

// Form
import Select from "react-select";
import { useForm, Controller, ErrorMessage } from "react-hook-form";

function AppOrderDetail({
  auth,
  user,
  orders,
  settings,
  settingsFlorida,
  payment,
  setContainerFixed,
}) {
  const dispatch = useDispatch();
  let history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  let { orderId } = useParams();

  const [showAuthForm, setShowAuthForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [
    showCancellationConfirmation,
    setShowCancellationConfirmation,
  ] = useState(false);

  const [hideSelectCard, setHideSelectCard] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  //const [saveNewCard, setSaveNewCard] = useState(false);
  const [showPaymentConfirmation, setShowPaymentConfirmation] = useState(false);
  const [cardsOptions, setCardsOptions] = useState([
    {
      label: "New Card",
      value: "newCard",
    },
  ]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const [deliveryDateString, setDeliveryDateString] = useState("");
  const [deliveryDateString2, setDeliveryDateString2] = useState("");
  const [pickUpDateString, setPickUpDateString] = useState("");
  const [allowReschedulePickUp, setAllowReschedulePickUp] = useState(true);
  const [allowRescheduleDelivery, setAllowRescheduleDelivery] = useState(true);
  const [showRescheduleForm, setShowRescheduleForm] = useState(false);
  const [showRescheduleError, setShowRescheduleError] = useState(false);
  const [orderTotal, setOrderTotal] = useState(null);
  const [message, setMessage] = useState();
  const [showAdminForms, setShowAdminForms] = useState(false);
  const [count, setCount] = useState(0);

  const { register, errors, control, watch, handleSubmit } = useForm({
    mode: "onChange",
  });

  const card = watch("card");

  useEffect(() => {
    dispatch(getSettings());
    dispatch(getSettingsFlorida());
    dispatch(getOrder(orderId));
  }, [orderId]);

  useEffect(() => {
    if (orders.order && settings) {
      setOrder(orders.order);

      let currentOrder = orders.order;

      let isFlorida = false;
      if (currentOrder.address?.state?.toLowerCase() == "fl") {
        isFlorida = true;
      }

      let result = calculatePricing({
        order: currentOrder,
        settings: isFlorida ? settingsFlorida : settings,
        gratuity: currentOrder.gratuity,
      });

      if (result) {
        setOrderTotal(result?.grandTotal);
      } else if (currentOrder.quoteTotalPrice) {
        setOrderTotal(currentOrder.quoteTotalPrice);
      }
    }
  }, [orders.order, settings]);

  useEffect(() => {
    if (user && user.role == "Customer") {
      dispatch(getPaymentMethods());
    }
  }, [user]);

  useEffect(() => {
    setCount(count);
    if (user && user.role !== "Customer") {
      setShowAdminForms(true);
    } else {
      setShowAdminForms(false);
    }
  }, [orderTotal]);

  // Display customer's saved cards
  useEffect(() => {
    if (payment.cards && user && user.role === "Customer") {
      let cards = [
        {
          label: "New Card",
          value: "newCard",
        },
      ];

      payment.cards.forEach((card) => {
        cards.push({
          label: card.card.last4,
          value: card.id,
        });
      });

      setCardsOptions(cards);
    }
  }, [payment]);

  // Save card (if selected)
  useEffect(() => {
    if (user && order) {
      if (user._id == order.user && user.role == "Customer") {
        dispatch(fetchSetupSecret(user));
      }
    }
  }, [order, user]);

  useEffect(() => {
    if (order) {
      if (order.status === "Pending Authorization") {
        setHideSelectCard(false);
      }

      let now = new Date();
      let clientOffset = now.getTimezoneOffset() / 60;
      let offsetDifference = clientOffset - 5;

      let pickup = new Date(order.pickUpDate);

      let delivery = new Date(order.deliveryDate);
      let delivery2,
        delivery2String,
        delivery2Arr,
        d2Month,
        d2Date,
        deliveryDateString2;

      if (order.deliveryDate2) {
        delivery2 = new Date(order.deliveryDate2);
        delivery2String = delivery2.toString();
        delivery2Arr = delivery2String.split(" ");

        d2Month = delivery2Arr[1];
        d2Date = delivery2Arr[2];
        deliveryDateString2 = `${d2Month} ${d2Date}`;
        setDeliveryDateString2(deliveryDateString2);
      }

      let pickupString = pickup.toString();
      let deliveryString = delivery.toString();

      let pickupArr = pickupString.split(" ");
      let deliveryArr = deliveryString.split(" ");

      // Hide reschedule order button if pickup has passed
      if (now > pickup) {
        setAllowReschedulePickUp(false);
        // setShowRescheduleFormButton(false);
      } else if (now > delivery) {
        setAllowReschedulePickUp(false);
        setAllowRescheduleDelivery(false);
      } else {
        let nowEST = now.getHours() + offsetDifference;

        // If pickup is scheduled for today
        if (now.getDate() == pickup.getDate() && order) {
          let nowEST = now.getHours() + offsetDifference;

          // Hide reschedule order button if less than 5 hours until pickup
          if (
            (order.pickUpTime &&
              order.pickUpTime.value &&
              order.pickUpTime.value - nowEST) < 5
          ) {
            setShowRescheduleError(true);
            setAllowReschedulePickUp(false);
          }

          // Hide reschedule order button if less than 5 hours until pickup
          if (
            (order.deliveryTime &&
              order.deliveryTime.value &&
              order.deliveryTime.value - nowEST) < 5
          ) {
            setShowRescheduleError(true);
            setAllowRescheduleDelivery(false);
          }
        }
      }

      let dMonth = deliveryArr[1];
      let dDate = deliveryArr[2];
      let pMonth = pickupArr[1];
      let pDate = pickupArr[2];

      let deliveryDateString = `${dMonth} ${dDate}`;
      setDeliveryDateString(deliveryDateString);

      let pickUpDateString = `${pMonth} ${pDate}`;

      setPickUpDateString(pickUpDateString);
    }
  }, [order]);

  const cardHandleChange = (e) => {
    const { error } = e;
    setError(error ? error.message : "");
    setProcessing(false);
  };

  const handleReorder = () => {
    dispatch(
      updateLocalOrder(order.address, "address", () => {
        console.log("done");
      })
    );

    if (order.washFoldOrder) {
      dispatch(
        updateLocalOrder(order.washFoldOrder, "washFoldOrder", () => {
          console.log("done");
        })
      );
    }

    if (order.organicWashFoldOrder) {
      dispatch(
        updateLocalOrder(
          order.organicWashFoldOrder,
          "organicWashFoldOrder",
          () => {
            console.log("done");
          }
        )
      );
    }

    if (order.washPressOrder) {
      dispatch(
        updateLocalOrder(order.washPressOrder, "washPressOrder", () => {
          console.log("done");
        })
      );
    }

    if (order.tailoringOrder) {
      dispatch(
        updateLocalOrder(order.tailoringOrder, "tailoringOrder", () => {
          console.log("done");
        })
      );
    }

    if (order.dryCleanOrder) {
      dispatch(
        updateLocalOrder(order.dryCleanOrder, "dryCleanOrder", () => {
          console.log("done");
        })
      );
    }

    if (order.comforterOrder) {
      dispatch(
        updateLocalOrder(order.comforterOrder, "comforterOrder", () => {
          console.log("done");
        })
      );
    }

    history.push("/app/order/services");
  };

  const onAuthSubmit = async (data) => {
    dispatch(
      authorizeGuest(data, () => {
        setShowAuthForm(false);
        dispatch(getOrder(orderId));
      })
    );
  };

  const openModal = () => {
    setShowModal(true);
    setContainerFixed(true);
  };

  const cancelOrder = () => {
    dispatch(
      updateOrderStatus(
        { status: { value: "Canceled" }, orderId: order.orderId },
        () => {
          setShowModal(false);
          setContainerFixed(false);
          setShowCancellationConfirmation(true);
        }
      )
    );
  };

  const handleEditOrder = () => {
    dispatch(
      setOrderToEdit(order, () => {
        history.push("/app/order/edit");
      })
    );
  };

  const onPaymentSubmit = async (data) => {
    if (!order || !order.reauthAmount) {
      return;
    }

    let amountToAuthorize = parseInt(order.reauthAmount);

    setProcessing(true);

    let secret = await getPaymentIntent({
      user,
      amount: amountToAuthorize,
      order,
    });

    if (!secret) {
      return;
    }

    let paymentArgs;

    if (!hideSelectCard && card && card.value != "newCard") {
      paymentArgs = {
        payment_method: card.value,
      };
    } else {
      paymentArgs = {
        payment_method: {
          card: elements.getElement(CardNumberElement),
        },
      };
    }

    let payload = await stripe.confirmCardPayment(secret, paymentArgs);

    if (!payload || payload.error) {
      setProcessing(false);
      setError(payload?.error?.message || "Payment error.");

      return;
    } else {
      // Update the payment intent id and amount authorized for this order
      // Save card to account

      // try {
      //   if (
      //     !card ||
      //     (card.value == "newCard" && payment.secret && saveNewCard)
      //   ) {
      //     stripe.confirmCardSetup(payment.secret, {
      //       payment_method: {
      //         card: elements.getElement(CardNumberElement),
      //       },
      //     });
      //   }
      // } catch (err) {
      //   console.log(err);
      // }

      dispatch(
        updateOrderPayment(
          {
            orderId: order._id,
            paymentIntentId: payload.paymentIntent.id,
            amountAuthorized: amountToAuthorize,
          },
          () => {
            setShowPaymentConfirmation(true);
            setProcessing(false);
          }
        )
      );
    }
  };

  useEffect(() => {
    setLoading(false);
    if (!auth.authorizedAsGuest && !auth.authenticated) {
      setShowAuthForm(true);
    } else {
      setShowAuthForm(false);
    }
  }, [auth]);

  if (orderTotal && (auth.authenticated || auth.authorizedAsGuest)) {
    return (
      <div className="dashboard-container">
        {showModal && <div className="modal-overlay"></div>}
        <Sidebar />
        <div className="dashboard-panel-container">
          <div
            id="order-detail"
            className={`${
              loading ? "view-container loading" : "view-container"
            }`}
          >
            {showModal && (
              <div className="modal">
                <div className="modal-content">
                  <h2>Are you sure you want to cancel this order?</h2>
                  <div className="action-button-container">
                    <button className="confirm" onClick={() => cancelOrder()}>
                      Yes, Cancel Order
                    </button>
                    <button
                      className="cancel"
                      onClick={() => {
                        setShowModal(false);
                        setContainerFixed(false);
                      }}
                    >
                      No, Do Not Cancel Order
                    </button>
                  </div>
                </div>
              </div>
            )}

            {showAuthForm && (
              <form id="guest-auth-form" onSubmit={handleSubmit(onAuthSubmit)}>
                <h4 style={{ marginBottom: "25px" }}>
                  Enter the email address you used to book this order.
                </h4>

                {auth.error && <p>{auth.error}</p>}
                <div className="form-line">
                  <input
                    name="email"
                    placeholder="Email Address"
                    ref={register({
                      required: "Please enter your email address.",
                    })}
                  />
                  <ErrorMessage errors={errors} name="email">
                    {({ message }) => (
                      <p className="form-error-message">{message}</p>
                    )}
                  </ErrorMessage>
                </div>
                <div className="form-line">
                  <input
                    type="hidden"
                    name="orderId"
                    ref={register}
                    value={orderId}
                  />
                </div>

                <div className="action-button-container">
                  <input
                    className="submit-button bg-green"
                    type="submit"
                    value="Log In"
                  />
                </div>
              </form>
            )}

            {!showAuthForm && (
              <div id="order-summary" className="full">
                {error && <p>{error}</p>}

                {(showCancellationConfirmation ||
                  order.status == "Canceled") && (
                  <div className="cancellation-confirmation">
                    This order has been canceled. If a payment has already been
                    processed,{" "}
                    {!user || user.role === "Customer"
                      ? "your credit card will be refunded."
                      : "please refund the customer below. "}
                    Otherwise the temporarily hold will be automatically removed
                    within 7 days.
                  </div>
                )}
                <h3>Order #{order.orderId}</h3>

                {order.status !== "Canceled" && (
                  <div className="order-detail-status-forms">
                    <div className="order-detail-status">
                      <h4>Status:</h4>
                      <StatusForm order={order} status={order.status} />
                    </div>
                    <div className="order-detail-location">
                      <h4>Location:</h4>
                      <LocationForm
                        order={order}
                        location={order.location}
                        locationName={order.locationName}
                      />
                    </div>
                  </div>
                )}

                {order.reauthStatus === "Complete" && (
                  <div className="reauth-module">
                    Thank you for reauthorizing your card! You will receive a
                    confirmation once your payment has been processed.
                  </div>
                )}

                {order.reauthStatus === "Waiting" &&
                  user?.role !== "SuperAdmin" && (
                    <div className="reauth-module">
                      {order.reauthAmount && (
                        <div className="reauth-text">
                          <div>
                            <strong>
                              Please authorize your card for the updated amount:
                              ${(order.reauthAmount / 100)?.toFixed(2)}
                            </strong>
                          </div>
                          {order.reauthNotes && <div>{order.reauthNotes}</div>}
                        </div>
                      )}

                      {showPaymentConfirmation && (
                        <div className="reauth-text">
                          Thanks re-authorizing your card for this order! You'll
                          receive an email when your payment has been processed.
                        </div>
                      )}

                      {order.reauthStatus == "Waiting" &&
                        user?.role !== "SuperAdmin" &&
                        cardsOptions &&
                        !showPaymentConfirmation && (
                          <form
                            id="reauthorize-form"
                            onSubmit={handleSubmit(onPaymentSubmit)}
                          >
                            {!hideSelectCard && (
                              <Controller
                                as={
                                  <Select
                                    options={cardsOptions}
                                    placeholder="Select Card"
                                  />
                                }
                                control={control}
                                name="card"
                                rules={{
                                  required: false,
                                  validate: (value) => value !== "Select Card",
                                }}
                              />
                            )}

                            {(hideSelectCard ||
                              (card && card.value == "newCard")) && (
                              <div className="stripe-card">
                                <div className="stripe-element-container">
                                  <CardNumberElement
                                    className="card-element"
                                    onChange={cardHandleChange}
                                  />
                                </div>

                                <div className="stripe-element-container">
                                  <CardExpiryElement
                                    className="card-element"
                                    onChange={cardHandleChange}
                                  />
                                </div>

                                <div className="stripe-element-container">
                                  <CardCvcElement
                                    className="card-element"
                                    onChange={cardHandleChange}
                                  />
                                </div>

                                {/* {!auth.authorizedAsGuest && (
                                  <div className="save-card-container">
                                    <label>Save Card</label>
                                    <input
                                      type="checkbox"
                                      checked={saveNewCard}
                                      onChange={() =>
                                        setSaveNewCard(!saveNewCard)
                                      }
                                    />
                                  </div>
                                )} */}
                              </div>
                            )}
                            <div className="action-button-container">
                              <input
                                className="submit-button bg-green"
                                type="submit"
                                disabled={processing}
                                value={
                                  processing
                                    ? "Processing..."
                                    : "Reauthorize Card"
                                }
                              />
                            </div>
                          </form>
                        )}
                    </div>
                  )}

                {order.status === "Completed" && user?.role == "Customer" && (
                  <div className="action-button-container">
                    <button
                      onClick={() => handleReorder()}
                      className="reschedule-button"
                    >
                      Reorder
                    </button>
                  </div>
                )}

                {order.status !== "Completed" && order.status !== "Canceled" && (
                  <div className="action-button-container">
                    {(order.status === "New" ||
                      order.status === "Confirmed" ||
                      (user &&
                        (user.role === "SuperAdmin" ||
                          user.role === "Admin"))) && (
                      <button
                        onClick={() => openModal()}
                        className="delete-button"
                      >
                        Cancel Order
                      </button>
                    )}

                    {user &&
                      (user.role === "Admin" || user.role === "SuperAdmin") && (
                        <button
                          onClick={handleEditOrder}
                          className="edit-button"
                        >
                          Edit Order
                        </button>
                      )}
                  </div>
                )}

                <ul>
                  {// DRY CLEAN
                  order.dryCleanOrder && (
                    <li className="order-item">
                      <div className="item-name">
                        <div className="service-name">Dry Clean</div>
                        <div className="service-quote">
                          ${order.dryCleanOrder.quote?.toFixed(2)}
                        </div>
                      </div>
                      <div className="item-details">
                        {order.dryCleanOrder.items.map((item) => {
                          let starchString;
                          if (item.starch === "noStarch") {
                            starchString = "No Starch";
                          } else if (item.starch === "lightStarch") {
                            starchString = "Light Starch";
                          } else if (item.starch === "heavyStarch") {
                            starchString = "Heavy Starch";
                          }

                          return (
                            <div>
                              <p>
                                <span>
                                  {item.type} x{item.quantity}
                                </span>
                                {(item.pressOnly ||
                                  item.crease ||
                                  item.starch) && (
                                  <>
                                    ({item.pressOnly && <span>Press Only</span>}
                                    <span>{item.crease && "Crease"}</span>
                                    <span>{item.starch && starchString}</span>)
                                  </>
                                )}
                              </p>
                            </div>
                          );
                        })}

                        {order.dryCleanOrder.updateNotes && (
                          <p className="order-update-notes">
                            {order.dryCleanOrder.updateNotes}
                          </p>
                        )}
                      </div>
                    </li>
                  )}

                  {// WASH & FOLD
                  order.washFoldOrder && (
                    <li className="order-item">
                      <div className="item-name">
                        <div className="service-name">
                          Wash & Fold{" "}
                          {order.isExpressDelivery &&
                            "(3 Hour Express Delivery)"}
                        </div>
                        <div className="service-quote">
                          ${order.washFoldOrder.quote?.toFixed(2)}
                        </div>
                      </div>
                      <div className="item-details">
                        <div>
                          <p>{order.washFoldOrder.weight} lbs</p>
                          {order.washFoldOrder.updateNotes && (
                            <p classame="order-update-notes">
                              {order.washFoldOrder.updateNotes}
                            </p>
                          )}
                        </div>

                        {settings?.products.map((product) => {
                          if (
                            order.washFoldOrder.products &&
                            order.washFoldOrder.products[product.item]
                          ) {
                            return (
                              <p>
                                {product.item} (${product.price?.toFixed(2)})
                              </p>
                            );
                          }
                        })}
                      </div>
                    </li>
                  )}

                  {// ORGANIC WASH & FOLD
                  order.organicWashFoldOrder && (
                    <li className="order-item">
                      <div className="item-name">
                        <div className="service-name">
                          Organic Wash & Fold{" "}
                          {order.isExpressDelivery &&
                            "(3 Hour Express Delivery)"}
                        </div>
                        <div className="service-quote">
                          ${order.organicWashFoldOrder.quote.toFixed(2)}
                        </div>
                      </div>
                      <div className="item-details">
                        <div>
                          <p>{order.organicWashFoldOrder.weight} lbs</p>
                          {order.organicWashFoldOrder.updateNotes && (
                            <p className="order-update-notes">
                              {order.organicWashFoldOrder.updateNotes}
                            </p>
                          )}
                        </div>
                      </div>
                    </li>
                  )}

                  {// TAILORING
                  order.tailoringOrder && (
                    <li className="order-item">
                      <div className="item-name">
                        <div className="service-name">Tailoring</div>
                        <div className="service-quote">
                          ${order.tailoringOrder.quote?.toFixed(2)}
                        </div>
                      </div>
                      <div className="item-details">
                        {order.tailoringOrder.items.map((item) => {
                          return (
                            <div>
                              <p>
                                <span>{item.type}</span>
                                {
                                  <>
                                    (
                                    {item.services.map((service) => {
                                      return <span>{service}</span>;
                                    })}
                                    )
                                  </>
                                }
                              </p>
                              <p>{item.instructions}</p>
                            </div>
                          );
                        })}

                        {order.tailoringOrder.updateNotes && (
                          <p className="order-update-notes">
                            {order.tailoringOrder.updateNotes}
                          </p>
                        )}
                      </div>
                    </li>
                  )}

                  {// WASH & PRESS
                  order.washPressOrder && (
                    <li className="order-item">
                      <div className="item-name">
                        <div className="service-name">Wash & Press</div>
                        <div className="service-quote">
                          ${order.washPressOrder.quote?.toFixed(2)}
                        </div>
                      </div>
                      <div className="item-details">
                        {order.washPressOrder.items.map((item) => {
                          return (
                            <div key={item.type}>
                              <p>
                                <span>
                                  {item.type} x{item.quantity}
                                </span>
                              </p>
                            </div>
                          );
                        })}
                        {order.washPressOrder.updateNotes && (
                          <p className="order-update-notes">
                            {order.washPressOrder.updateNotes}
                          </p>
                        )}
                      </div>
                    </li>
                  )}

                  {// COMFORTER
                  order.comforterOrder && (
                    <li className="order-item">
                      <div className="item-name">
                        <div className="service-name">Comforter</div>
                        <div className="service-quote">
                          ${order.comforterOrder.quote?.toFixed(2)}
                        </div>
                      </div>
                      <div className="item-details">
                        {order.comforterOrder.items.map((item) => {
                          return (
                            <div key={item.type}>
                              <p>
                                <span>
                                  {item.type} x{item.quantity}
                                </span>
                              </p>
                            </div>
                          );
                        })}
                        {order.comforterOrder.updateNotes && (
                          <p className="order-update-notes">
                            {order.comforterOrder.updateNotes}
                          </p>
                        )}
                      </div>
                    </li>
                  )}
                </ul>

                {order.minimumDeliverySurcharge &&
                  order.minimumDeliverySurcharge > 0 && (
                    <div className="order-surcharge order-additional-items">
                      <div className="additional-items-label">
                        Minimum Delivery Surcharge:
                      </div>
                      <div className="additional-items-total">
                        ${order.minimumDeliverySurcharge?.toFixed(2)}
                        {/* {order.status !== "Canceled" &&
                                            <UpdateServiceForm orderId={order._id} service="minimumDeliverySurcharge" />
                                        } */}
                      </div>
                    </div>
                  )}

                {order.dryCleanSurcharge && order.dryCleanSurcharge > 0 && (
                  <div className="order-surcharge order-additional-items">
                    <div className="additional-items-label">
                      Same-Day Dry Clean Surcharge:
                    </div>
                    <div className="additional-items-total">
                      ${order.dryCleanSurcharge?.toFixed(2)}
                      {/* {order.status !== "Canceled" && <UpdateServiceForm orderId={order._id} service="dryCleanSurcharge" />} */}
                    </div>
                  </div>
                )}

                {order.totalDiscountAmount && order.totalDiscountAmount > 0 && (
                  <div className="order-gratuity order-additional-items">
                    <div className="additional-items-label">
                      Promo Discount:
                    </div>
                    <div className="additional-items-total">
                      -${parseFloat(order.totalDiscountAmount)?.toFixed(2)}
                    </div>
                  </div>
                )}

                {order.gratuity && order.gratuity > 0 && (
                  <div className="order-gratuity order-additional-items">
                    <div className="additional-items-label">
                      Tip for Driver:
                    </div>
                    <div className="additional-items-total">
                      ${parseFloat(order.gratuity)?.toFixed(2)}
                    </div>
                  </div>
                )}

                <div className="order-total order-additional-items">
                  <div className="additional-items-label">Order Total:</div>
                  <div className="additional-items-total">
                    ${orderTotal?.toFixed(2)}
                  </div>
                </div>

                {order.finalOrderTotalPrice && (
                  <div className="order-total order-additional-items">
                    <div className="additional-items-label">Charged:</div>
                    <div className="additional-items-total">
                      ${order.finalOrderTotalPrice?.toFixed(2)}
                    </div>
                  </div>
                )}

                {order.refundAmount && (
                  <div className="order-total order-additional-items">
                    <div className="additional-items-label">Refunded:</div>
                    <div className="additional-items-total">
                      ${order.refundAmount?.toFixed(2)}
                    </div>
                  </div>
                )}

                {order.refundNotes && <div>{order.refundNotes}</div>}

                {order.chargeNotes && <div>{order.chargeNotes}</div>}

                {order.deliveryDate2 && (
                  <div>
                    <em>Multiple deliveries for this order.</em>
                  </div>
                )}

                <div className="order-details">
                  {order.address && (
                    <div className="address">
                      <h5>Address</h5>
                      <p>{order.address.address}</p>
                      <p>{order.address.address2}</p>
                      <p>{order.address.city}</p>
                      <p>{order.address.state}</p>
                      <p>{order.address.zip}</p>
                    </div>
                  )}

                  <div className="contact">
                    <h5>Contact</h5>
                    <p>{order.firstName}</p>
                    <p>{order.email}</p>
                    <p>{order.phone}</p>
                    <p>
                      SMS Notifications {order.sms ? "Enabled" : "Disabled"}
                    </p>
                  </div>

                  <div className="pickUp">
                    <h5>Pick Up Time</h5>
                    <p>{pickUpDateString}</p>
                    <p>{order.pickUpTime?.label}</p>
                  </div>

                  <div className="delivery">
                    <h5>Delivery Time</h5>

                    <p>{deliveryDateString}</p>
                    <p>{order.deliveryTime?.label}</p>
                  </div>

                  {order.deliveryDate2 && (
                    <div className="delivery2">
                      <h5>Delivery Time</h5>
                      <p>{deliveryDateString2}</p>
                      <p>{order.deliveryTime2?.label}</p>
                    </div>
                  )}

                  {order.instructions && (
                    <div>
                      <h5>Instructions</h5>
                      <p>{order.instructions}</p>
                    </div>
                  )}
                </div>

                {message && <h4>{message}</h4>}

                {showRescheduleError && (
                  <h4>
                    Sorry, this order is no longer eligible for rescheduling.
                    Please contact us if you have any questions.
                  </h4>
                )}

                {(allowRescheduleDelivery || allowReschedulePickUp) &&
                  !showRescheduleForm &&
                  order.status !== "Completed" &&
                  order.status !== "Canceled" && (
                    <div className="action-button-container">
                      <button
                        onClick={() => setShowRescheduleForm(true)}
                        className="reschedule-button"
                      >
                        Reschedule Order
                      </button>
                    </div>
                  )}

                {showRescheduleForm && (
                  <RescheduleForm
                    order={order}
                    setMessage={setMessage}
                    close={setShowRescheduleForm}
                    allowRescheduleDelivery={allowRescheduleDelivery}
                    allowReschedulePickUp={allowReschedulePickUp}
                  />
                )}

                {showAdminForms && orderTotal && (
                  <div>
                    {order.status !== "Payment Processed" &&
                      order.status !== "Completed" &&
                      order.status !== "Refund Processed" &&
                      order.status !== "Canceled" && (
                        <CapturePaymentForm orderTotal={orderTotal} />
                      )}

                    {(order.status == "Completed" ||
                      order.status == "Payment Processed" ||
                      order.status == "Canceled") && (
                      <RefundForm order={order} />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ minHeight: "50vh", padding: "100px", textAlign: "center" }}>
        {showAuthForm && (
          <form id="guest-auth-form" onSubmit={handleSubmit(onAuthSubmit)}>
            <h4 style={{ marginBottom: "25px" }}>
              Enter the email address you used to book this order.
            </h4>

            {auth.error && <p>{auth.error}</p>}
            <div className="form-line">
              <input
                name="email"
                placeholder="Email Address"
                ref={register({ required: "Please enter your email address." })}
              />
              <ErrorMessage errors={errors} name="email">
                {({ message }) => (
                  <p className="form-error-message">{message}</p>
                )}
              </ErrorMessage>
            </div>
            <div className="form-line">
              <input
                type="hidden"
                name="orderId"
                ref={register}
                value={orderId}
              />
            </div>

            <div className="action-button-container">
              <input
                className="submit-button bg-green"
                type="submit"
                value="Log In"
              />
            </div>
          </form>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    orders: state.orders,
    user: state.auth.user,
    payment: state.payment,
    settings: state.settings,
    settingsFlorida: state.settingsFlorida,
  };
};

export default connect(mapStateToProps)(AppOrderDetail);
